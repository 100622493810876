import React from 'react';
import './App.css';

function App() {
  const roleDescriptionStyle = {
    fontSize: '1.2rem',
    margin: '50px 0 20px', // Increased top margin for more space
    textAlign: 'center',
    color: '#000',
    fontWeight: 'bold',
  };

  return (
    <div className="App" style={{ backgroundColor: '#fff', padding: '30px', minHeight: '100vh' }}>
      <header className="App-header" style={{ textAlign: 'center', maxWidth: '800px', margin: '0 auto' }}>
        {/* Name and Title */}
        <h1 className="name-title" style={{ fontSize: '3rem', fontWeight: 'bold', margin: '10px 0' }}>Ramyar Daneshgar</h1>
        <p className="title-subtext" style={{ fontSize: '1.2rem', margin: '10px 0', fontWeight: 'normal' }}>Security Engineer</p>
        <p className="viterbi-subtext" style={{ fontSize: '1rem', margin: '5px 0', fontWeight: 'normal' }}>
          University of Southern California Viterbi School of Engineering
        </p>
        <p style={roleDescriptionStyle}>
          &nbsp;I help build and invest in companies that make people feel safe.
        </p>

        {/* Contact Icons Section */}
        <div className="contact-icons" style={{ display: 'flex', gap: '30px', justifyContent: 'center', marginTop: '30px' }}>
          {/* Email */}
          <a
            href="mailto:ramyarda@usc.edu"
            aria-label="Send an Email to Ramyar Daneshgar"
            style={{ color: '#000', fontSize: '2rem', textDecoration: 'none' }}
          >
            <i className="fas fa-envelope"></i>
          </a>

          {/* GitHub */}
          <a
            href="https://github.com/ramyardaneshgar"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit Ramyar Daneshgar's GitHub Profile"
            style={{ color: '#000', fontSize: '2rem', textDecoration: 'none' }}
          >
            <i className="fab fa-github"></i>
          </a>

          {/* TryHackMe */}
          <a
            href="https://tryhackme.com/r/p/securityengineer"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Explore Ramyar Daneshgar's TryHackMe Profile"
            style={{ color: '#000', fontSize: '2rem', textDecoration: 'none' }}
          >
            <i className="fas fa-hat-wizard"></i>
          </a>

          {/* LinkedIn */}
          <a
            href="https://www.linkedin.com/in/ramyardaneshgar/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit Ramyar Daneshgar's LinkedIn Profile"
            style={{ color: '#000', fontSize: '2rem', textDecoration: 'none' }}
          >
            <i className="fab fa-linkedin"></i>
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
